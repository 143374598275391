var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"transition-hide":"none","transition-show":"none"},model:{value:(_vm.proxyModel),callback:function ($$v) {_vm.proxyModel=$$v},expression:"proxyModel"}},[_c('q-card',{staticStyle:{"min-width":"80%","background-color":"#f5f9fc"}},[_c('div',{staticClass:"text-right q-mt-sm q-mr-sm"},[_c('q-btn',{attrs:{"flat":"","dense":"","icon":_vm.$icons.matClose,"color":"grey","round":"","size":"md"},on:{"click":function($event){_vm.proxyModel = false}}})],1),_c('div',{staticClass:"flex-y flex-1 q-px-xl q-pb-xl"},[_c('div',{staticClass:"text-center q-py-md"},[_c('strong',{staticClass:"an-20 medium-text cursor-pointer",class:[
            _vm.agreementDialog.title === _vm.policy.title ? '' : 'greybtn--text',
          ],domProps:{"textContent":_vm._s(_vm.policy.title)},on:{"click":function($event){_vm.agreementDialog.title !== _vm.policy.title
              ? _vm.agreementDialogHandler(_vm.policy)
              : null}}}),_c('strong',{staticClass:"an-20 medium-text cursor-pointer q-ml-sm",class:[
            _vm.agreementDialog.title === _vm.terms.title ? '' : 'greybtn--text',
          ],domProps:{"textContent":_vm._s(_vm.terms.title)},on:{"click":function($event){_vm.agreementDialog.title !== _vm.terms.title
              ? _vm.agreementDialogHandler(_vm.terms)
              : null}}})]),_c('div',{staticClass:"flex-1 scroll-y",staticStyle:{"border":"5px solid #e0e7ef"}},[_c('div',{ref:"termscontainer",staticClass:"dialog-content bg-white q-pa-md",attrs:{"id":"termscontainer"}},[_c('div',{staticClass:"relative-position",domProps:{"innerHTML":_vm._s(_vm.agreementDialog.desc)}})])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }