<template>
  <div class="an-14 greybtn--text demi-bold-text q-mt-sm">
    <slot></slot> <span class="timer" v-if="onlyseconds">{{ time }}</span>
    <span class="timer" v-else>{{ prettyTime }}</span
    ><slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "timmer",
  props: ["minutes", "seconds", "onlyseconds"],
  data() {
    return {
      isRunning: false,
      time: 0,
      timer: null,
    };
  },
  beforeCreate() {
    clearInterval(this.timer);
    this.$eventBus.$off("startTimer");
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.$eventBus.$off("startTimer");
  },
  created() {
    this.$eventBus.$on("startTimer", this.start);
  },
  methods: {
    start() {
      this.setTime();
      this.isRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isRunning = false;
      clearInterval(this.timer);
      this.timer = null;
      this.$emit("timeover");
    },
    reset() {
      this.stop();
      this.time = 0;
    },
    setTime() {
      if (this.minutes && this.seconds) {
        this.time = this.minutes * 60 + this.seconds;
      } else {
        this.time = this.seconds ? this.seconds : 10;
      }
    },
  },
  computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let seconds = Math.round((time - minutes) * 60);
      return minutes + ":" + seconds;
    },
  },
};
</script>
