<template>
  <div class="signin-page">
    <div class="q-pa-md">
      <div
        @click="redirectToSite"
        class="cursor-pointer"
      >
        <img
          alt="logo"
          class="logo"
          src="/static/images/logo/logo.svg"
        />
      </div>
      <div class="right-side-view col-grow row relative-position">
        <div>
          <div class="row">
            <div class="q-mt-lg mobile-view-text-container">
              <div class="sigin-mobile-company-name">
                Welcome to Unity in Work
              </div>
              <div class="q-mt-sm sigin-mobile-company-name1">
                Get our app or visit heycollab.com on your desktop to sign in with your new credentials!
              </div>
            </div>
            <div class="q-my-lg row justify-between items-center" style="width: 100%;">
              <div style="width: 48%;">
                <a href="https://play.google.com/store/apps/details?id=com.hey_collab_react_native">
                  <img
                    src="https://vabotu.s3-us-west-1.amazonaws.com/google.svg"
                    alt="Download on the app store"
                    style="width: 100%;"
                  />
                </a>
              </div>
              <div style="width: 48%;">
                <a href="https://apps.apple.com/us/app/heycollab/id1552464623">
                  <img
                    src="https://vabotu.s3-us-west-1.amazonaws.com/apple.svg"
                    alt="Download on the app store"
                    style="width: 100%;"
                  />
                </a>
              </div>
            </div>
            <div class="mobile-view-image-container">
              <img 
                class="image"
                src="https://vabotu.s3-us-west-1.amazonaws.com/tasks.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import has from "lodash/has";
import size from "lodash/size";

import { login, getUser, getWorkspaces } from "@/services/auth";

import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import validationMixin from "@/mixins/validationMixin";

import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { validCompanyUri } from "@/utils/validators";

import {
  CompanyQuery,
  UserActionLogsMutation,
  Workspaces2Query,
  CheckCompanyStatusMutation,
} from "@/gql";

const initialState = () => {
  return {
    stage: 0,
    errors: {
      checkCompanyUri: null,
      singIn: null,
    },
    company: {
      id: null,
      uri: null,
      name: null,
      public_link: null,
    },
    email: null,
    password: null,
    forgotCompanyDialog: false,
    forgotPasswordDialog: false,
    loader: {
      stage1: false,
      stage2: false,
    },
    defaultView: null,
    termsConditionDialog: false,
  };
};

export default {
  name: "MobileSigninView",
  mixins: [validationMixin, ProxyModelMixin],
  validations: {
    email: { required, email },
    password: { required },
    company: {
      uri: {
        required,
        validCompanyUri,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
    },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
    password: {
      required: "message.validation.password.required",
    },
    company: {
      uri: {
        required: "message.validation.company.uri.required",
        maxLength: "message.validation.company.uri.max",
        minLength: "message.validation.company.uri.min",
        validCompanyUri: "message.validation.company.uri.validurl",
      },
    },
  },
  components: {},
  data() {
    return initialState();
  },
  methods: {
    redirectToSite() {
      window.open("https://heycollab.com/");
    },
    async checkCompanyUri() {
      this.loader.stage1 = true;
      const variables = {
        uri: this.company.uri,
      };

      try {
        const response = await this.$api.query({
          query: CompanyQuery,
          variables,
        });

        const company = response.data.company;
        if (!company) {
          this.errors.checkCompanyUri = this.$t(
            "message.validation.company.notExist"
          );
        } else {
          // Route to dashboard if company already authenticated
          if (has(this.companies, company.uri)) {
            this.$store.dispatch("activeCompany", company.uri);
            this.$store.dispatch("companyStatus", {
              isActive: company.status,
              subscriptionOver: false,
              graceHours: 0,
              hasPaycard: true,
            });
            this.handleLoggedInCompany(company);
            this.loader.stage1 = false;
          } else {
            this.company.id = company.id;
            this.company.name = company.name;
            this.company.public_link = company.public_link;
            this.company.pricing_version = company.pricing_version;
            this.company.logo = company.logo;
            this.company.status = company.status;
            this.stage = 2;
          }
        }
        this.loader.stage1 = false;
      } catch (error) {
        this.errors.checkCompanyUri = "Something went wrong.";
        this.loader.stage1 = false;
      }
    },
    async singIn() {
      this.loader.stage2 = true;
      let credential = {
        username: this.email,
        password: this.password,
        company: this.company.uri,
      };
      const result = await login(credential);
      this.loader.stage2 = false;

      if (has(result, "error")) {
        this.errors.singIn = this.$t("message.validation.credentialNotMatch");
      } else {
        const user = await getUser(result.access_token);
        const { data } = await getWorkspaces(
          result.access_token,
          Workspaces2Query
        );
        let redirectWorkspaceId = null;
        const filteredWorkspaces = data.workspaces2.filter(
          (w) => !w.is_general && w.type !== "people" && w.type !== "personal"
        );
        if (filteredWorkspaces.length > 0) {
          redirectWorkspaceId = filteredWorkspaces[0].id;
        } else {
          redirectWorkspaceId = 1;
        }
        const shareData = {
          companyUri: this.company.uri,
          companyName: this.company.name,
          companyId: this.company.id,
          logo: this.company.logo,
          pricing_version: this.company.pricing_version,
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          walkthrough: user.last_session_at <= user.created_at,
          redirectWorkspaceId,
          status: this.company.status,
          isActive: this.company.status === "active",
        };
        if (user.status === "inactive") {
          this.errors.singIn =
            user.company.user_id === user.id
              ? this.$t("message.validation.ownerInactive")
              : this.$t("message.validation.memberInactive");
        } else {
          this.$store.dispatch("addToCompanies", shareData);
          this.$store.dispatch("activeCompany", this.company.uri);
          this.$store.dispatch(
            "companyStatus",
            this.company.status === "active"
          );

          const checkCompany = await this.$api.mutate({
            mutation: CheckCompanyStatusMutation,
          });
          const status = {
            isActive:
              checkCompany.data.checkCompanyStatus.company_status === "active",
            subscriptionOver:
              checkCompany.data.checkCompanyStatus.subscription_over,
            graceHours: checkCompany.data.checkCompanyStatus.grace_hours,
            hasPaycard: checkCompany.data.checkCompanyStatus.hasPaycard,
          };
          this.$store.dispatch("companyStatus", status);

          this.handleLoginSuccess(shareData);
          let variables = {
            action: "login",
            user_id: user.id,
          };
          await this.$api.mutate({
            mutation: UserActionLogsMutation,
            variables,
          });
        }
      }
      this.loader.stage2 = false;
    },
    handleLoggedInCompany(company) {
      if (company && company.status === "active") {
        this.$router.push({
          name: "BoardView",
          params: {
            company: company.uri,
            workspace: 1,
          },
        });
      } else {
        this.$router.push(`/companies/${company.uri}/payment`);
      }
    },
    handleLoginSuccess(company) {
      if (company) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("message.company.login"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        if (company.isActive) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: company.companyUri,
              workspace: company.redirectWorkspaceId,
            },
          });
        } else {
          this.$router.push(`/companies/${company.companyUri}/payment`);
        }
      }
    },
    forgotPasswordSuccessHandler() {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("message.company.forgot.password"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
      this.forgotPasswordDialog = false;
    },
    forgetCompanyHandler(data) {
      if (data) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("message.company.forgot.name"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.forgotCompanyDialog = false;
      }
    },
    closeForgotCompany() {
      this.forgotCompanyDialog = false;
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    switchCompany(activeCompanyUri) {
      this.$store.dispatch("activeCompany", activeCompanyUri);
      this.$router.push({
        name: "BoardView",
        params: {
          company: activeCompanyUri,
          workspace: 1,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
    }),
    showCompaniesList() {
      return size(this.companies);
    },
    isDisabledStage1() {
      if (this.$v.company.uri.$invalid || this.loader.stage1 === true) {
        return true;
      }
      return false;
    },
    isDisabledStage2() {
      if (
        this.$v.email.$invalid ||
        this.$v.password.$invalid ||
        this.loader.stage2 === true
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    proxyModel(val) {
      if (!val) return;
      this.$track.page("virtual/signin");
      requestAnimationFrame(() => {
        this.$refs.focus.focus();
      });
    },
  },
};
</script>
