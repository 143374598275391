<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card style="min-width:80%;background-color:#f5f9fc">
      <div class="text-right q-mt-sm q-mr-sm">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="grey"
          round
          size="md"
          @click="proxyModel = false"
        />
      </div>
      <div class="flex-y flex-1 q-px-xl q-pb-xl">
        <div class="text-center q-py-md">
          <strong
            class="an-20 medium-text cursor-pointer"
            v-text="policy.title"
            @click="
              agreementDialog.title !== policy.title
                ? agreementDialogHandler(policy)
                : null
            "
            :class="[
              agreementDialog.title === policy.title ? '' : 'greybtn--text',
            ]"
          ></strong>
          <strong
            class="an-20 medium-text cursor-pointer q-ml-sm"
            v-text="terms.title"
            @click="
              agreementDialog.title !== terms.title
                ? agreementDialogHandler(terms)
                : null
            "
            :class="[
              agreementDialog.title === terms.title ? '' : 'greybtn--text',
            ]"
          ></strong>
        </div>
        <div class="flex-1 scroll-y" style="border: 5px solid #e0e7ef">
          <div
            class="dialog-content bg-white q-pa-md"
            id="termscontainer"
            ref="termscontainer"
          >
            <div class="relative-position" v-html="agreementDialog.desc"></div>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import TermsConditionMixin from "@/mixins/TermsConditionMixin";

import { terms, policy } from "@/utils/Contents";

export default {
  name: "TermsConditionDialog",
  props: {
    defaultView: {
      type: String,
      default: "terms",
    },
  },
  mixins: [ProxyModelMixin, TermsConditionMixin],
  data() {
    return {
      terms,
      policy,
      agreementDialog: {
        title: null,
        desc: null,
      },
    };
  },
  mounted() {
    if (this.defaultView === "terms") {
      this.agreementDialogHandler(this.terms);
    } else {
      this.agreementDialogHandler(this.policy);
    }
  },
  beforeDestroy() {
    const ps = this.$refs.termscontainer;
    const hasClickEventClass = ps.getElementsByClassName("termsatag");
    const element = hasClickEventClass[0];
    element.addEventListener("click", this.clickHandler);
  },
  methods: {
    agreementDialogHandler(arg = terms) {
      this.agreementDialog = arg;
      this.$nextTick(() => {
        const ps = this.$refs.termscontainer;
        ps.scrollTop = 0;

        const hasClickEventClass = ps.getElementsByClassName("termsatag");
        if (hasClickEventClass.length > 0) {
          const element = hasClickEventClass[0];
          element.addEventListener("click", this.clickHandler);
        }
      });
    },
    clickHandler() {
      const ps = this.$refs.termscontainer;

      const hasClickEventClass = ps.getElementsByClassName("termsatag");
      const element = hasClickEventClass[0];
      let dataSet = element.dataset.id;
      let content;
      switch (dataSet) {
        case "policy":
          content = this.policy;
          break;
        case "terms":
          content = this.terms;
          break;
        default:
          content = null;
          break;
      }
      if (content === null) {
        this.closeAgreementDialogHandler();
      } else {
        this.agreementDialogHandler(content);
      }
    },
    closeAgreementDialogHandler() {
      this.proxyModel = false;
      this.agreementDialog = {
        title: null,
        desc: null,
      };
    },
  },
};
</script>
