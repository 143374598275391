export const validNumber = async value => (value ? !isNaN(value) : true);

export const uniqueUser = async value =>
  value ? value.match(/^[\w.-]+$/i) : true;

export const validPassword = async value =>
  value
    ? value.match(
        /^(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$%^&*()?.'"/])[\w!@#$%^&*()?.'"/]{8,16}$/
      )
    : true;
// export const validCompanyUri = async value => value ? value.match(/^[a-z\d]+(-[a-z\d]+)*$/i) : true
export const validName = async value =>
  value ? value.match(/^[a-zA-Z]+$/) : true;
export const validCompanyUri = async value =>
  value ? value.match(/^[a-z0-9][a-z0-9-_]*[a-z0-9]$/i) : true;
  //value ? value.match(/^[a-z0-9]*[a-z0-9]$/i) : true;

export const validWorkspaceTitle = async value =>
  value ? value.match(/^[a-z0-9][a-z0-9-_]*[a-z0-9]$/i) : true;

export const cityCountry = async value =>
  value ? value.match(/^[a-z0-9][a-z0-9\s-~&':.]*[a-z0-9]$/i) : true;

export const validUrl = async value =>
  value
    ? value.match(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'(\\)*+,;=.]+$/i
      )
    : true;

export const validUrl2 = async value =>
  value
    ? value.match(/((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i)
    : true;
