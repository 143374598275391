import { terms, policy } from "@/utils/Contents";
export default {
  data() {
    return {
      terms,
      policy,
      termsConditionDialog: false,
      agreementDialog: {
        title: null,
        desc: null,
      },
    };
  },
  methods: {
    agreementDialogHandler(arg = terms) {
      this.termsConditionDialog = true;
      this.agreementDialog = arg;
      this.$nextTick(() => {
        // const ps = this.$refs.termscontainer;
        const ps = document.getElementById("termscontainer");
        const hasClickEventClass = ps.getElementsByClassName("termsatag");
        if (hasClickEventClass && hasClickEventClass.length > 0) {
          const element = hasClickEventClass[0];
          let dataSet = element.dataset.id;
          element.addEventListener("click", () => {
            let content;
            switch (dataSet) {
              case "policy":
                content = this.policy;
                break;
              case "terms":
                content = this.terms;
                break;
              default:
                content = null;
                break;
            }
            if (content === null) {
              this.closeAgreementDialogHandler();
            } else {
              this.agreementDialogHandler(content);
            }
          });
        }
      });
    },
    closeAgreementDialogHandler() {
      this.termsConditionDialog = false;
      this.agreementDialog = {
        title: null,
        desc: null,
      };
    },
  },
};
