import MaskedInput from "vue-masked-input";
import timmer from "@/components/Stock/timmer";
import {
  GetVerificationCodeMutation,
  CheckVerificationCodeMutation,
  SendPasswordResetEmail
} from "@/gql";

export default {
  data() {
    return {
      code: null,
      invalidCodeMessage: null
    };
  },
  components: {
    MaskedInput,
    timmer
  },
  mounted() {
    this.$eventBus.$emit("startTimer");
  },
  methods: {
    async checkVerificationMutation(variables) {
      const response = await this.$api.mutate({
        mutation: CheckVerificationCodeMutation,
        variables
      });
      return response;
    },
    async sendVerificationCode() {
      this.startTimer = true;

      this.$eventBus.$emit("startTimer");

      let variables = { email: this.email };

      await this.$api.mutate({
        mutation: GetVerificationCodeMutation,
        variables
      });
    },
    async sendPasswordResetCode() {
      this.startTimer = true;

      this.$eventBus.$emit("startTimer");

      let variables = { email: this.email, company_uri:this.companyUri };

      await this.$api.mutate({
        mutation: SendPasswordResetEmail,
        variables
      });
    }
  },
  computed: {
    isValidCode() {
      if (this.code && this.code.match(/^[0-9]+$/)) return true;
      return false;
    }
  }
};
